import * as policyUtils from "../../../utils/policyUtils";
import { components } from "../../../api/schema";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import { shouldDisableDefaultPolicyInputs } from "../utils";
import ApplyingRecommendationOption from "./ApplyingRecommendationOption";

enum WorkloadsTypes {
  deploymentRecommendationType = "Deployment",
  statefulSetRecommendationType = "StatefulSet",
  daemonSetRecommendationType = "DaemonSet",
  // jobRecommendationType = "Job",
  deploymentConfigRecommendationType = "DeploymentConfig",
  argoRolloutRecommendationType = "Rollout",
  familyRecommendationType = "Custom Workload",

}

const fetchers = {
  deploymentRecommendationType: policyUtils.policyUpdateDeploymentMode,
  deploymentConfigRecommendationType: policyUtils.policyUpdateDeploymentConfigMode,
  daemonSetRecommendationType: policyUtils.policyUpdateDaemonSetMode,
  // "WorkloadsTypes.jobRecommendationType": policyUtils.policyUpdateJobMode,
  statefulSetRecommendationType: policyUtils.policyUpdateStatefulSetMode,
  familyRecommendationType: policyUtils.policyUpdateFamilySetMode,
  argoRolloutRecommendationType: policyUtils.policyUpdateArgoRolloutMode,
};
interface Props {
  isCustomizedPolicy?: boolean;
  defaultPolicy?: components["schemas"]["V1alpha1Policy"] | undefined;
}

const ApplyingRecommendationSettings = ({ isCustomizedPolicy, defaultPolicy }: Props) => {
  return (
    <div className="flex flex-col gap-4 py-10 border-b border-strongBorder">
      <FormTitleAndDescription
        title="Automation optimization strategy"
        description="Define your optimization strategy per workload types."
      />
      <div className="flex flex-col gap-4 mt-1">
        {Object.entries(WorkloadsTypes).map(([key, value]) => {
          const defaultValue = isCustomizedPolicy ? fetchers[key as keyof typeof fetchers](defaultPolicy) : undefined;
          return (
            <ApplyingRecommendationOption
              key={key}
              label={value}
              name={key}
              disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
              isCustomizedPolicy={isCustomizedPolicy}
              defaultValue={defaultValue}
            />
          );
        })}
      </div>
    </div>
  );
};
export default ApplyingRecommendationSettings;
