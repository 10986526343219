import { Dialog, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import CloseIcon from "../Icons/CloseIcon";
import MagicStars from "../Icons/MagicStars";
import ScaleopsIcon from "../Icons/ScaleopsIcon";
import { GetVersion, GetVersionResponse } from "../api/fetcher";
import { components } from "../api/schema";
import Button, { BUTTON_VARIANTS } from "../components/Button";
import useAskForPremium from "../utils/useAskForPremium";

const getVersion = GetVersion();

const UpgradeRequestCTA = ({ closeDialogue, daysLeft }: { closeDialogue: () => void; daysLeft?: number }) => {
  const askForPremium = useAskForPremium();

  const handleClick = () => {
    closeDialogue();
    askForPremium.mutate();
  };

  return (
    <>
      <Typography variant="body1" fontWeight={600}>
        Upgrade your ScaleOps account &#127881;
      </Typography>
      <Typography variant="body2" fontWeight={400}>
        You have <b>{daysLeft} days</b> left on your free trial.
      </Typography>
      <Button
        variant={BUTTON_VARIANTS.mediumDarkPurple}
        className="w-fit mt-5"
        onClick={handleClick}
        label={
          <div className="flex items-center gap-2">
            <MagicStars width={20} height={20} />
            <span>Request an upgrade!</span>
          </div>
        }
      />
    </>
  );
};

const UpgradeRequestWasSentMessage = () => (
  <>
    <Typography variant="body1" fontWeight={600}>
      Your upgrade request was sent successfully &#127881;
    </Typography>
    <Typography variant="body2" fontWeight={400}>
      We will get back to you shortly.
    </Typography>
  </>
);

const FreeTrialNotification = () => {
  const [freeTrialData, setFreeTrialData] = useState<components["schemas"]["VersionFreeTrial"] | undefined>(
    undefined
  );
  const [isDialogueOpen, setIsDialogueOpen] = useState<boolean>(false);

  const { data } = useQuery<GetVersionResponse>({
    queryKey: [getVersion.queryKey],
    queryFn: getVersion.queryFn,
  });

  useEffect(() => {
    setFreeTrialData(data?.freeTrial);
  }, [data]);

  const handleClick = () => {
    setIsDialogueOpen(true);
  };

  const closeDialogue = () => {
    setIsDialogueOpen(false);
  };

  if (!freeTrialData) {
    return null;
  }

  return (
    <>
      <Button
        variant={BUTTON_VARIANTS.mediumDarkPurple}
        className="w-full"
        onClick={handleClick}
        label={
          <div className="flex items-center gap-2">
            <MagicStars width={20} height={20} />
            <span>Upgrade now</span>
          </div>
        }
      />
      {freeTrialData?.daysLeft !== undefined && (
        <Typography variant="caption">{freeTrialData?.daysLeft} days left</Typography>
      )}
      <Dialog
        open={isDialogueOpen}
        onClose={closeDialogue}
        fullWidth={true}
        sx={{
          ".MuiDialog-paper": {
            borderRadius: "20px",
          },
        }}
      >
        <div className="dialogue content flex flex-col items-center justify-center py-16 relative">
          <CloseIcon
            className="absolute top-5 right-5 cursor-pointer"
            onClick={() => {
              setIsDialogueOpen(false);
            }}
          />
          {!freeTrialData?.hasAskedForPremium && (
            <UpgradeRequestCTA closeDialogue={closeDialogue} daysLeft={freeTrialData.daysLeft} />
          )}
          {freeTrialData?.hasAskedForPremium && <UpgradeRequestWasSentMessage />}
          <ScaleopsIcon width={100} height={100} className="mt-5 info-icon-animation" />
        </div>
      </Dialog>
    </>
  );
};
export default FreeTrialNotification;
